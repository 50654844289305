<template>
  <v-container
      fill-height
      fluid
  >
    <v-layout
        justify-center
        align-center
    >
      <template v-if="loaded">
        <data-table ref="datatable"
                    :key="uniqueKey"
                    :api-end-point="apiurl"
                    :search="search"
                    :sort-order="sortOrder"
                    :table-name="'Therapist Payouts'"
                    sub-table-name="(Money Out)"
                    :isDetail="false"
                    :isExport="true"
                    :btn-import-white-bg="true"
                    exportUrl="bills"
                    exportBtnText="Download"
                    :export-param="'&status='+ currentTab.name"
                    :isImport="true"
                    :is-custom-filter="false"
                    @import="showImportCsvModal"
                    :pre-selected-filters="filters.selectedFilters"
                    :showGrayHeader="true"
        >
            <template slot="tabs">
              <template v-for="tab in tabs">
                  <tabs
                  :current-tab="currentTab"
                  :tab="tab"
                  @changeTab="changeTab"
                  ></tabs>
              </template>
            </template>

          <template slot="filters">
            <multi-select-filters :filter-id="'therapist_filter'"
                                  :filters="filters.therapistFilter"
                                  :placeholder="'Select therapist'"
                                  :column="'therapist_id'"
                                  :operator="'in'"
            ></multi-select-filters>
            <!-- Date Range Filter -->
              <date-range-filter :filter-id="'bill_date_filter'"
                                 :query-type="'Date'"
                                 :column="dateFilterColumn"
                                 :operator="'between'"
                                 :place-holder="dateFilterName"
                                 :parent-class="'mr-5'"
              >
              </date-range-filter>
          </template>
        </data-table>
      </template>

      <!-- Import CSV -->
      <import-csv v-if="importCsvDialog"
                  :api-url="'bills'"
                  :visible="importCsvDialog"
                  v-on:hide="hideImportCsvModal"
                  v-on:updateTab="fetchTabs"
      >
      </import-csv>
    </v-layout>
  </v-container>
</template>

<script>

import DataTable from "@/components/common/dataTable/DataTable";
import Filters from "@/components/common/dataTable/filters/Filters";
import MultiSelectFilters from "@/components/common/dataTable/filters/MultiSelectFilters";
import DateRangeFilter from "@/components/common/dataTable/filters/DateRangeFilter";
import ImportCsv from "@/components/main/ImportCsv";
import Tabs from "@/components/common/dataTable/tabs";

export default {
  name: "Bills",
  components: {
    Tabs,
    Filters,
    DataTable,
    MultiSelectFilters,
    DateRangeFilter,
    ImportCsv
  },
  data(){
    return {
      apiurl: 'bills',
      uniqueKey: 'bills',
      importCsvDialog: false,
      billsDetailDialog: false,
      search: {
        placeholder: 'Search by Therapist name, Payout id…',
        searchAble: true
      },
      sortOrder: [
        {
          field: 'therapist.full_name',
          sortField: 'therapist_first_name',
          direction: 'asc'
        }
      ],
      selectedBill: null,
      filters: null,
      loaded: false,
      date: '',
      tabs: null,
      currentTab: null,
      dateFilterColumn: "due_date",
      dateFilterName: "Due date ",
    }
  },
  created() {
    this.fetchFilters();
  },
  methods: {
    changeTab(tab) {
      this.apiurl = "bills?status=" + tab.name;
      this.uniqueKey = tab.name;
      this.currentTab = tab;
      this.dateFilterColumn = tab.name === "In external system" ? "due_date" : "paid_date";
      this.dateFilterName = tab.name === "In external system" ? "Due date " : "Date paid ";
    },
    fetchFilters() {
      this.$http.get(this.url + 'filters/bills').then((res) => {
        if (res.data) {
          this.filters = res.data;
          this.fetchTabs();
        } else {
          alert('there is some problem');
        }
      });
    },
    fetchTabs() {
      this.$http.get(this.url + 'tabs/bills').then((res) => {
        if (res.data) {
          this.tabs = res.data;
          this.currentTab = this.tabs[0];
          this.apiurl = "bills?status=" + this.currentTab.name;
          this.loaded = true;
        } else {
          alert('there is some problem');
        }
      });
    },
    showImportCsvModal() {
      this.importCsvDialog = true;
    },
    hideImportCsvModal() {
      this.importCsvDialog = false;
      this.fetchTabs();
    },
  },
  events: {
    'import-csv': function() {
      this.importCsvDialog = true;
      this.showImportCsvModal();
    },

  },


}
</script>

<style scoped>

</style>
